<template>
  <div class="order-container">
    <!-- 筛选区域 -->
    <van-sticky>
      <div class="screen-box">
        <!-- 顶部搜索 -->
        <div class="search-container">
          <van-icon name="arrow-left" @click="handleClickLeft" size="22" />
          <van-search ref="searchInput" class="search-order" v-model="params.searchOrderNum" placeholder="输入订单号/商品名称搜索" shape="round" inputmode="search" :clearable="false" @search="handleSearchOrder" @blur="handleBlur" />
        </div>
        <!-- 选择团员 -->
        <div class="junior-select-box">
          <div class="junior-select" @click="showMyJuniorPicker = true">
            {{ screenUser.memberNickname ? `已选 | ${screenUser.memberNickname}` : "所有团员" }}
            <van-icon name="arrow" size="12" v-if="!screenUser.memberNickname" />
          </div>
        </div>
        <van-popup v-model="showMyJuniorPicker" round position="bottom">
          <van-picker show-toolbar :columns="myUserList" value-key="memberNickname" cancel-button-text="重置" @cancel="resetChooseAction" @confirm="chooseMyJuniorAction" />
        </van-popup>
        <!-- tab 状态选项 -->
        <div class="tab-btn-group">
          <div class="tab-btn" :class="{ 'active-tab': params.type == item.value }" v-for="(item, index) in orderStatusList" :key="index" @click="handleChangeTab(item.value)" v-show="item.value != 5 || isHaveAlreadyOrder">
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </van-sticky>
    <!-- 列表 -->
    <div class="order-list-container">
      <van-list v-model="loading" :finished="finished" :finished-text="isEmpty ? '' : '没有更多了'" @load="getOrders">
        <div class="order-list" v-if="orderList.length > 0">
          <div class="order-item" v-for="(item, index) in orderList" :key="index">
            <div class="order-item-top">
              <div class="order-no-info">
                <div class="order-no">订单号：{{ item.orderId }}</div>
                <div class="order-no order-detail-id">商品行编号：{{ item.orderDetailId }}</div>
              </div>
              <div class="status-tip" :class="`status-${item.orderDetailStatus}`">
                {{ getOrderStatusName(item.orderDetailStatus) }}
              </div>
            </div>
            <div class="product-info">
              <img class="product-img" :src="item.productImgUrl" />
              <div class="product-detail">
                <div class="product-name">{{ item.productName }}</div>
                <div class="buy-info-box">
                  <div class="buy-info buy-num">购买数量：<span>{{ item.productQuantity }}</span></div>
                  <div class="buy-info">实付金额：<span>¥{{ item.payAmount }}</span></div>
                </div>
                <div class="buy-info-box" v-if="item.refundNum > 0">
                  <div class="buy-info buy-num">退款数量：<span>{{ item.refundNum }}</span></div>
                  <div class="buy-info">退款金额：<span>¥{{ item.refundPrice }}</span></div>
                </div>
                <div class="order-time">创建时间：{{ item.orderCreateTime }}</div>
              </div>
            </div>
            <!-- 项目总展示 -->
            <div class="project-manage">
              <div class="project-manage-item">
                <div class="commission-name">佣金比例</div>
                <div class="commission-value">{{ item.commissionRatio }}</div>
              </div>
              <div class="project-manage-item" v-if="item.orderDetailStatus == 4 || item.orderDetailStatus == 5">
                <div class="commission-name">实际分佣金额</div>
                <div class="commission-value color-orange">{{ item.effectiveCommission }}</div>
              </div>
              <div class="project-manage-item" v-else>
                <div class="commission-name">预计分佣金额</div>
                <div class="commission-value color-orange">{{ item.estimateCommission }}</div>
              </div>
            </div>
            <!-- 下单用户信息 -->
            <div class="order_tips_view">
              <span style="font-weight: bold;">{{ item.orderTips || "-" }}</span>
              <span style="font-weight: bold;">丨</span>
              <span>{{ `下单用户：${item.memberNickname || "-"}` }}</span>
            </div>
          </div>
        </div>
        <!-- 托底图 -->
        <div class="empty-box" v-if="isEmpty">
          <img class="empty-img" src="../../../assets/icon_empty.png" alt="">
          <div class="empty-text">暂无数据</div>
        </div>
      </van-list>
    </div>
    <!-- 列表 end -->
  </div>
</template>

<script>
import API from '@/common/axios';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh');
import { Toast } from 'vant';

export default {
  data() {
    return {
      orderList: [],
      loading: false,
      finished: false,
      myUserList: [{memberNickname: "所有团员", userId: "-1"}],
      showMyJuniorPicker: false,
      isHaveAlreadyOrder: 0,
      screenUser: {},
      orderStatusList: [
        { name: '全部', value: 0 },
        { name: '待付款', value: 1 },
        { name: '已付款', value: 2 },
        { name: '已取消', value: 3 },
        { name: '待结算', value: 4 },
        { name: '已结算', value: 5 },
      ],
      params: {
        searchOrderNum: '', // 搜索的订单号
        screenUserId: '', // 筛选的团员uid
        type: 0, // 查看数据的类型：0 全部 1代付款 2.已付款 3已取消 4已结算 5待结算
        pageSize: 20,
        pageIndex: 1,
      },
      isClickSearch: false, // 是否点击过搜索按钮
      searchKeyword: '', // 搜索的订单号
      isEmpty: false, // 列表是否为空
    };
  },
  created() {
    document.title = "订单明细";
    // 获取登录信息，未登录跳转登录页面
    let accountInfo = localStorage.getItem('groupManagerAccountInfo');
    if (!accountInfo) {
      this.$router.replace('/groupManager/login');
      return;
    }
    this.getMyUserList();
    this.getAlreadyOrderStatus();
  },
  methods: {
    // 获取团长下的团员列表
    getMyUserList() {
      API({
        url: "/h5/team/getLeagueMemberList.do",
        data: {
          type: 0
        }
      }).then(res => {
        if (res.success == 1) {
          this.myUserList = this.myUserList.concat(res.data);
        } else {
          Toast(res.errMsg || "请重试！");
        }
      }).catch(_ => {
        Toast("服务异常，请刷新页面重试！");
      });
    },
    // 获取团长是否存在已结算 订单
    getAlreadyOrderStatus() {
      API({
        url: "/h5/team/haveAlreadyOrder.do",
        data: {}
      }).then(res => {
        if (res.success == 1) {
          this.isHaveAlreadyOrder = res.data;
        }
      });
    },
    // 获取订单列表
    getOrders() {
      this.loading = true;
      API({
        url: "/h5/team/getCommissionOrderList.do",
        data: this.params,
      }).then(res => {
        if (res.success == 1) {
          let list = res.data.objs;

          if (this.params.pageIndex === 1) {
            this.orderList = list;
            this.isEmpty = list.length === 0;
            // 页面滚动到顶部
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
          } else {
            this.orderList = [...this.orderList, ...list];
          }

          this.loading = false;
          if (list.length === 0) {
            this.finished = true;
          } else {
            this.params.pageIndex++;
          }
        } else {
          Toast(res.errMsg || "请重试！");
        }
      }).catch(_ => {
        Toast("服务异常，请刷新页面重试！");
      });
    },
    // 获取状态名称
    getOrderStatusName(statusValue) {
      const status = this.orderStatusList.find(status => status.value === statusValue);
      return status ? status.name : '未知状态';
    },
    // 点击返回 跳转首页
    handleClickLeft() {
      this.$router.replace('/groupManager/home');
    },
    // 选择下级
    chooseMyJuniorAction(e) {
      this.showMyJuniorPicker = false;
      if (e.userId == "-1") {
        this.resetChooseAction();
        return;
      }
      if (this.screenUser.memberNickname == e.memberNickname || this.screenUser.userId == e.userId) {
        return;
      }
      this.screenUser = { ...e };
      this.params.screenUserId = e.userId;
      this.resetList();
      this.getOrders();
    },
    resetChooseAction() {
      this.showMyJuniorPicker = false;
      if (!this.screenUser.memberNickname) {
        return;
      }
      this.screenUser = {};
      this.params.screenUserId = '';
      this.resetList();
      this.getOrders();
    },
    // 搜索订单
    handleSearchOrder(val) {
      this.params.searchOrderNum = val.trim();
      this.isClickSearch = true;
      this.searchKeyword = val.trim();
      this.resetList();
      this.getOrders();
      // 让输入框失去焦点以关闭软键盘
      this.$nextTick(() => {
        if (this.$refs.searchInput) {
          this.$refs.searchInput.getElementsByTagName('input')[0].blur();
        }
      });
    },
    handleBlur() {
      // 未点击搜索按钮收起键盘后清空搜索栏
      if (!this.isClickSearch) {
        this.params.searchOrderNum = '';
      } else {
        this.params.searchOrderNum = this.searchKeyword;
      }
    },
    // 切换订单选项卡
    handleChangeTab(val) {
      this.params.type = val;
      this.resetList();
      this.getOrders();
    },
    // 重置列表组件数据
    resetList() {
      this.orderList = [];
      this.params.pageIndex = 1;
      this.loading = false;
      this.finished = false;
      this.isEmpty = false;
    },
  }
};
</script>
<style>
body {
  background: #F5F5F7;
}
</style>
<style lang="scss" scoped>
.order-container {
  position: relative;
  background: url("../../../assets/property_bg.png") no-repeat;
  background-size: 100%;
  height: 100vh;
}

// 搜索
.search-container {
  height: 44px;
  user-select: none;
  color: #323233;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  margin: 10px auto 12px;

  .search-order {
    flex: 1;
    margin-left: 12px;
  }

  .van-search {
    padding: 0;
    background-color: transparent;
    height: 30px;
    font-size: 13px;

    .van-search__content {
      background: #ffffff;
    }

    .van-cell {
      padding: 3px 8px 3px 0;

      /deep/ .van-field__control {
        font-size: 13px !important;
      }
    }
  }
}

.screen-box {
  overflow: hidden;
  background: url("../../../assets/property_bg.png") no-repeat;
  background-size: 100%;
}

.junior-select-box {
  display: flex;
  justify-content: flex-end;
  padding-right: 12px;
  margin-bottom: 16px;

  .junior-select {
    height: 24px;
    border-radius: 12px;
    border: 1px solid #784CFA;
    color: #784CFA;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
  }
}

// tab
.tab-btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px 12px;

  .tab-btn {
    font-size: 14px;
    border-radius: 4px;
    text-align: center;
    line-height: 20px;
    color: #333333;
    position: relative;
    height: 29px;
  }

  .active-tab {
    color: #784CFA;
    font-size: 16px;

    ::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 16px;
      height: 3px;
      background: #784CFA;
      border-radius: 1px;
    }
  }
}

// 订单
.order-list-container {
  margin-bottom: 20px;
  padding: 0 10px 20px;
}

.order-item {
  position: relative;
  overflow: hidden;
  padding: 10px 12px 12px;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 10px;

  .order-item-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #F5F5F7;
    margin-bottom: 12px;
  }

  .order-no {
    font-size: 13px;
    color: #999999;
    line-height: 19px;
  }

  .order-time {
    font-size: 12px;
    color: #666666;
    line-height: 17px;
    margin-top: 7px;
  }

  .product-info {
    display: flex;

    .product-img {
      width: 70px;
      height: 70px;
      border-radius: 8px;
    }

    .product-detail {
      width: 100px;
      flex: 1;
      margin-left: 10px;

      .product-name {
        // 超出一行省略
        font-weight: 500;
        font-size: 16px;
        color: #4A4A4A;
        line-height: 19px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .buy-info-box {
        display: flex;
        flex-wrap: wrap;
        margin-top: 9px;
        gap: 15px;

        .buy-info {
          color: #666666;
          font-size: 14px;
          line-height: 19px;

          span {
            color: #4A4A4A;
            font-weight: 600;
          }
        }
      }
    }
  }

  .status-tip {
    width: 63px;
    height: 24px;
    background: #F0F0F0;
    border-radius: 6px;
    line-height: 24px;
    text-align: center;
    font-size: 13px;
    color: #666666;

    &.status-1 {
      background: #EBFFEE;
      color: #50AD5E;
    }

    &.status-2 {
      background: #EBF8FF;
      color: #48A0CE;
    }

    &.status-3 {
      background: #F0F0F0;
      color: #666666;
    }

    &.status-4 {
      background: #FFF3E8;
      color: #FF7D00;
    }

    &.status-5 {
      background: #EFEAFF;
      color: #784CFA;
    }
  }

  // 项目总展示
  .project-manage {
    height: 68px;
    background: #F7F8FA;
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin-top: 12px;

    .project-manage-item {
      box-sizing: border-box;
      width: 50%;
      text-align: center;

      .commission-name {
        font-size: 12px;
        color: #666666;
        line-height: 17px;
      }

      .commission-value {
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        line-height: 25px;
      }

      .color-orange {
        color: #FF7D00;
      }

      &:last-child {
        border-left: 1px solid #D4D4D4;
      }
    }
  }

  .order_tips_view {
    line-height: 18px;
    font-size: 13px;
    margin-top: 12px;
    color: #333;
  }
}

.empty-box {
  .empty-img {
    display: block;
    width: 140px;
    margin: 50px auto 0;
  }

  .empty-text {
    text-align: center;
    font-size: 14px;
    color: #666666;
    line-height: 20px;
    margin-top: 4px;
  }
}
</style>